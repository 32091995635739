import React, { Suspense, useRef, useEffect, useMemo, useState } from 'react';
import styles from './Home.module.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import googleLogo from '../Assets/google_g_icon_download.webp';
import Samples from './Samples.js';

import zthrxd3ytaqilmaofcdy from '../Assets/sdSamples/zthrxd3ytaqilmaofcdy.webp';
import ah5ngmcv8fdglzxansrc from '../Assets/sdSamples/ah5ngmcv8fdglzxansrc.webp';
import ajvrk94ouur0oos7cj3z from '../Assets/sdSamples/ajvrk94ouur0oos7cj3z.webp';
import bevdvkiydroyphw45icm from '../Assets/sdSamples/bevdvkiydroyphw45icm.webp';
import cs8ky5gvz93cme4hgcv0 from '../Assets/sdSamples/cs8ky5gvz93cme4hgcv0.webp';
import divueliqizqskfa9qe15 from '../Assets/sdSamples/divueliqizqskfa9qe15.webp';
import dpxc5hxlhpqmflvcaemc from '../Assets/sdSamples/dpxc5hxlhpqmflvcaemc.webp';
import eg2gtg1jhrebxubtlqin from '../Assets/sdSamples/eg2gtg1jhrebxubtlqin.webp';
import eshceagmdbfflguvroqc from '../Assets/sdSamples/eshceagmdbfflguvroqc.webp';
import fyplz8kevjvqktvnfm3e from '../Assets/sdSamples/fyplz8kevjvqktvnfm3e.webp';
import g0f58ykakmedvd0qzrif from '../Assets/sdSamples/g0f58ykakmedvd0qzrif.webp';
import hyhje84tehjxguar2jnf from '../Assets/sdSamples/hyhje84tehjxguar2jnf.webp';
import ia5oshoe30s2qwp8ckq8 from '../Assets/sdSamples/ia5oshoe30s2qwp8ckq8.webp';
import iehbjxndzz6wel8rgos0 from '../Assets/sdSamples/iehbjxndzz6wel8rgos0.webp';
import ijwfeqqzepyygszzeqt2 from '../Assets/sdSamples/ijwfeqqzepyygszzeqt2.webp';
import ippqhdpz8pwgvfai9fmo from '../Assets/sdSamples/ippqhdpz8pwgvfai9fmo.webp';
import iuhjbf719itcjvvfxmjr from '../Assets/sdSamples/iuhjbf719itcjvvfxmjr.webp';
import jdlstjfz34ubpash0s5r from '../Assets/sdSamples/jdlstjfz34ubpash0s5r.webp';
import jytzo93ol64ejcp69mxi from '../Assets/sdSamples/jytzo93ol64ejcp69mxi.webp';
import jzwco9huzxnd5cxvwtli from '../Assets/sdSamples/jzwco9huzxnd5cxvwtli.webp';
import kuye7aecbejqb7uctifg from '../Assets/sdSamples/kuye7aecbejqb7uctifg.webp';
import l41sqzm4vd6xuy4wectb from '../Assets/sdSamples/l41sqzm4vd6xuy4wectb.webp';
import lgqwdpbdzkvdmqsysn4q from '../Assets/sdSamples/lgqwdpbdzkvdmqsysn4q.webp';
import muhdrvjpxtpfuqfvgzjj from '../Assets/sdSamples/muhdrvjpxtpfuqfvgzjj.webp';
import n4emiloxmbifwunzbmcv from '../Assets/sdSamples/n4emiloxmbifwunzbmcv.webp';
import ocbmwd3nlud7qbglemos from '../Assets/sdSamples/ocbmwd3nlud7qbglemos.webp';
import odhngyl5xxaltopo7l0c from '../Assets/sdSamples/odhngyl5xxaltopo7l0c.webp';
import p4qhe6fffzttsdpak6vg from '../Assets/sdSamples/p4qhe6fffzttsdpak6vg.webp';
import pd9urkqfjdsyujhctwez from '../Assets/sdSamples/pd9urkqfjdsyujhctwez.webp';
import phuoxsymajxvquraki7a from '../Assets/sdSamples/phuoxsymajxvquraki7a.webp';
import pmsfr3vbdsticn8zf17f from '../Assets/sdSamples/pmsfr3vbdsticn8zf17f.webp';
import pyzbdnlbk2loh0wcvq2t from '../Assets/sdSamples/pyzbdnlbk2loh0wcvq2t.webp';
import qa9khkv6obuczjcaw7ux from '../Assets/sdSamples/qa9khkv6obuczjcaw7ux.webp';
import tdnqr1ixnzgnqvxdwfxh from '../Assets/sdSamples/tdnqr1ixnzgnqvxdwfxh.webp';
import tmsbdzjlqwekewaylaof from '../Assets/sdSamples/tmsbdzjlqwekewaylaof.webp';
import tywqykmw2gmahvpy85sj from '../Assets/sdSamples/tywqykmw2gmahvpy85sj.webp';
import u51dr7ofhsfkkwleeaj3 from '../Assets/sdSamples/u51dr7ofhsfkkwleeaj3.webp';
import urlhiswf4w24kjvfhrgh from '../Assets/sdSamples/urlhiswf4w24kjvfhrgh.webp';
import vesb6d3l6h2wfu3r8i0o from '../Assets/sdSamples/vesb6d3l6h2wfu3r8i0o.webp';
import vgce6qku8umnbychocjz from '../Assets/sdSamples/vgce6qku8umnbychocjz.webp';
import xdo3s2xgirfu4akgcy1f from '../Assets/sdSamples/xdo3s2xgirfu4akgcy1f.webp';
import ydmegr2y3l0brro6sn90 from '../Assets/sdSamples/ydmegr2y3l0brro6sn90.webp';
import yhtzgqkdukyw4q3d1pz9 from '../Assets/sdSamples/yhtzgqkdukyw4q3d1pz9.webp';

import zthrxd3ytaqilmaofcdy1 from '../Assets/sdSamples/thumb/zthrxd3ytaqilmaofcdy.webp';
import ah5ngmcv8fdglzxansrc1 from '../Assets/sdSamples/thumb/ah5ngmcv8fdglzxansrc.webp';
import ajvrk94ouur0oos7cj3z1 from '../Assets/sdSamples/thumb/ajvrk94ouur0oos7cj3z.webp';
import bevdvkiydroyphw45icm1 from '../Assets/sdSamples/thumb/bevdvkiydroyphw45icm.webp';
import cs8ky5gvz93cme4hgcv01 from '../Assets/sdSamples/thumb/cs8ky5gvz93cme4hgcv0.webp';
import divueliqizqskfa9qe151 from '../Assets/sdSamples/thumb/divueliqizqskfa9qe15.webp';
import dpxc5hxlhpqmflvcaemc1 from '../Assets/sdSamples/thumb/dpxc5hxlhpqmflvcaemc.webp';
import eg2gtg1jhrebxubtlqin1 from '../Assets/sdSamples/thumb/eg2gtg1jhrebxubtlqin.webp';
import eshceagmdbfflguvroqc1 from '../Assets/sdSamples/thumb/eshceagmdbfflguvroqc.webp';
import fyplz8kevjvqktvnfm3e1 from '../Assets/sdSamples/thumb/fyplz8kevjvqktvnfm3e.webp';
import g0f58ykakmedvd0qzrif1 from '../Assets/sdSamples/thumb/g0f58ykakmedvd0qzrif.webp';
import hyhje84tehjxguar2jnf1 from '../Assets/sdSamples/thumb/hyhje84tehjxguar2jnf.webp';
import ia5oshoe30s2qwp8ckq81 from '../Assets/sdSamples/thumb/ia5oshoe30s2qwp8ckq8.webp';
import iehbjxndzz6wel8rgos01 from '../Assets/sdSamples/thumb/iehbjxndzz6wel8rgos0.webp';
import ijwfeqqzepyygszzeqt21 from '../Assets/sdSamples/thumb/ijwfeqqzepyygszzeqt2.webp';
import ippqhdpz8pwgvfai9fmo1 from '../Assets/sdSamples/thumb/ippqhdpz8pwgvfai9fmo.webp';
import iuhjbf719itcjvvfxmjr1 from '../Assets/sdSamples/thumb/iuhjbf719itcjvvfxmjr.webp';
import jdlstjfz34ubpash0s5r1 from '../Assets/sdSamples/thumb/jdlstjfz34ubpash0s5r.webp';
import jytzo93ol64ejcp69mxi1 from '../Assets/sdSamples/thumb/jytzo93ol64ejcp69mxi.webp';
import jzwco9huzxnd5cxvwtli1 from '../Assets/sdSamples/thumb/jzwco9huzxnd5cxvwtli.webp';
import kuye7aecbejqb7uctifg1 from '../Assets/sdSamples/thumb/kuye7aecbejqb7uctifg.webp';
import l41sqzm4vd6xuy4wectb1 from '../Assets/sdSamples/thumb/l41sqzm4vd6xuy4wectb.webp';
import lgqwdpbdzkvdmqsysn4q1 from '../Assets/sdSamples/thumb/lgqwdpbdzkvdmqsysn4q.webp';
import muhdrvjpxtpfuqfvgzjj1 from '../Assets/sdSamples/thumb/muhdrvjpxtpfuqfvgzjj.webp';
import n4emiloxmbifwunzbmcv1 from '../Assets/sdSamples/thumb/n4emiloxmbifwunzbmcv.webp';
import ocbmwd3nlud7qbglemos1 from '../Assets/sdSamples/thumb/ocbmwd3nlud7qbglemos.webp';
import odhngyl5xxaltopo7l0c1 from '../Assets/sdSamples/thumb/odhngyl5xxaltopo7l0c.webp';
import p4qhe6fffzttsdpak6vg1 from '../Assets/sdSamples/thumb/p4qhe6fffzttsdpak6vg.webp';
import pd9urkqfjdsyujhctwez1 from '../Assets/sdSamples/thumb/pd9urkqfjdsyujhctwez.webp';
import phuoxsymajxvquraki7a1 from '../Assets/sdSamples/thumb/phuoxsymajxvquraki7a.webp';
import pmsfr3vbdsticn8zf17f1 from '../Assets/sdSamples/thumb/pmsfr3vbdsticn8zf17f.webp';
import pyzbdnlbk2loh0wcvq2t1 from '../Assets/sdSamples/thumb/pyzbdnlbk2loh0wcvq2t.webp';
import qa9khkv6obuczjcaw7ux1 from '../Assets/sdSamples/thumb/qa9khkv6obuczjcaw7ux.webp';
import tdnqr1ixnzgnqvxdwfxh1 from '../Assets/sdSamples/thumb/tdnqr1ixnzgnqvxdwfxh.webp';
import tmsbdzjlqwekewaylaof1 from '../Assets/sdSamples/thumb/tmsbdzjlqwekewaylaof.webp';
import tywqykmw2gmahvpy85sj1 from '../Assets/sdSamples/thumb/tywqykmw2gmahvpy85sj.webp';
import u51dr7ofhsfkkwleeaj31 from '../Assets/sdSamples/thumb/u51dr7ofhsfkkwleeaj3.webp';
import urlhiswf4w24kjvfhrgh1 from '../Assets/sdSamples/thumb/urlhiswf4w24kjvfhrgh.webp';
import vesb6d3l6h2wfu3r8i0o1 from '../Assets/sdSamples/thumb/vesb6d3l6h2wfu3r8i0o.webp';
import vgce6qku8umnbychocjz1 from '../Assets/sdSamples/thumb/vgce6qku8umnbychocjz.webp';
import xdo3s2xgirfu4akgcy1f1 from '../Assets/sdSamples/thumb/xdo3s2xgirfu4akgcy1f.webp';
import ydmegr2y3l0brro6sn901 from '../Assets/sdSamples/thumb/ydmegr2y3l0brro6sn90.webp';
import yhtzgqkdukyw4q3d1pz91 from '../Assets/sdSamples/thumb/yhtzgqkdukyw4q3d1pz9.webp';

import seen1 from '../Assets/icons/seenlogo1.webp';
import seen2 from '../Assets/icons/seenlogo2.webp';
import seen3 from '../Assets/icons/seenlogo3.webp';

import Faqs from './Faqs.js';
import Testimonials from './Testimonials';
import testimonialsData from '../Data/Testimonials.js';
import cloudIcon from '../Assets/icons/cloud-computing.png';
import aiIcon from '../Assets/icons/artificial-intelligence-2.png';
import picIcon from '../Assets/icons/gallery.webp';

import { useLocation, Link } from 'react-router-dom';
import Bundles from './Bundle/Bundles.js';
import dogUrl from '../Assets/sdSamples/sample_4k2.png';
import catUrl from '../Assets/sdSamples/sample_4k.png';

export default function Home() {
	const [ thumbStart, setThumbStart ] = useState(0);

	const isMounted = useRef(true);
	const [ index, setIndex ] = useState(0);

	const imagesArray = useMemo(
		() => [
			zthrxd3ytaqilmaofcdy,
			ah5ngmcv8fdglzxansrc,
			ajvrk94ouur0oos7cj3z,
			bevdvkiydroyphw45icm,
			cs8ky5gvz93cme4hgcv0,
			divueliqizqskfa9qe15,
			dpxc5hxlhpqmflvcaemc,
			eg2gtg1jhrebxubtlqin,
			eshceagmdbfflguvroqc,
			fyplz8kevjvqktvnfm3e,
			g0f58ykakmedvd0qzrif,
			hyhje84tehjxguar2jnf,
			ia5oshoe30s2qwp8ckq8,
			iehbjxndzz6wel8rgos0,
			ijwfeqqzepyygszzeqt2,
			ippqhdpz8pwgvfai9fmo,
			iuhjbf719itcjvvfxmjr,
			jdlstjfz34ubpash0s5r,
			jytzo93ol64ejcp69mxi,
			jzwco9huzxnd5cxvwtli,
			kuye7aecbejqb7uctifg,
			l41sqzm4vd6xuy4wectb,
			lgqwdpbdzkvdmqsysn4q,
			muhdrvjpxtpfuqfvgzjj,
			n4emiloxmbifwunzbmcv,
			ocbmwd3nlud7qbglemos,
			odhngyl5xxaltopo7l0c,
			p4qhe6fffzttsdpak6vg,
			pd9urkqfjdsyujhctwez,
			phuoxsymajxvquraki7a,
			pmsfr3vbdsticn8zf17f,
			pyzbdnlbk2loh0wcvq2t,
			qa9khkv6obuczjcaw7ux,
			tdnqr1ixnzgnqvxdwfxh,
			tmsbdzjlqwekewaylaof,
			tywqykmw2gmahvpy85sj,
			u51dr7ofhsfkkwleeaj3,
			urlhiswf4w24kjvfhrgh,
			vesb6d3l6h2wfu3r8i0o,
			vgce6qku8umnbychocjz,
			xdo3s2xgirfu4akgcy1f,
			ydmegr2y3l0brro6sn90,
			yhtzgqkdukyw4q3d1pz9
		],
		[]
	);

	const thumbsArray = useMemo(
		() => [
			zthrxd3ytaqilmaofcdy1,
			ah5ngmcv8fdglzxansrc1,
			ajvrk94ouur0oos7cj3z1,
			bevdvkiydroyphw45icm1,
			cs8ky5gvz93cme4hgcv01,
			divueliqizqskfa9qe151,
			dpxc5hxlhpqmflvcaemc1,
			eg2gtg1jhrebxubtlqin1,
			eshceagmdbfflguvroqc1,
			fyplz8kevjvqktvnfm3e1,
			g0f58ykakmedvd0qzrif1,
			hyhje84tehjxguar2jnf1,
			ia5oshoe30s2qwp8ckq81,
			iehbjxndzz6wel8rgos01,
			ijwfeqqzepyygszzeqt21,
			ippqhdpz8pwgvfai9fmo1,
			iuhjbf719itcjvvfxmjr1,
			jdlstjfz34ubpash0s5r1,
			jytzo93ol64ejcp69mxi1,
			jzwco9huzxnd5cxvwtli1,
			kuye7aecbejqb7uctifg1,
			l41sqzm4vd6xuy4wectb1,
			lgqwdpbdzkvdmqsysn4q1,
			muhdrvjpxtpfuqfvgzjj1,
			n4emiloxmbifwunzbmcv1,
			ocbmwd3nlud7qbglemos1,
			odhngyl5xxaltopo7l0c1,
			p4qhe6fffzttsdpak6vg1,
			pd9urkqfjdsyujhctwez1,
			phuoxsymajxvquraki7a1,
			pmsfr3vbdsticn8zf17f1,
			pyzbdnlbk2loh0wcvq2t1,
			qa9khkv6obuczjcaw7ux1,
			tdnqr1ixnzgnqvxdwfxh1,
			tmsbdzjlqwekewaylaof1,
			tywqykmw2gmahvpy85sj1,
			u51dr7ofhsfkkwleeaj31,
			urlhiswf4w24kjvfhrgh1,
			vesb6d3l6h2wfu3r8i0o1,
			vgce6qku8umnbychocjz1,
			xdo3s2xgirfu4akgcy1f1,
			ydmegr2y3l0brro6sn901,
			yhtzgqkdukyw4q3d1pz91
		],
		[]
	);

	const preload = [ zthrxd3ytaqilmaofcdy, ah5ngmcv8fdglzxansrc ];

	const [ imgsrc, setImgsrc ] = useState(imagesArray[index]);

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();
	let affrefid = query.get('afref');

	useEffect(
		() => {
			preload.forEach((each) => {
				let newImage = new Image();
				newImage.src = each;
			});

			function setTimer1() {
				const timer = setInterval(() => {
					if (isMounted.current) {
						setIndex((prevIndex) => {
							const currentIndex = (prevIndex + 1) % imagesArray.length;
							setImgsrc(imagesArray[currentIndex]);
							return currentIndex;
						});
					}
				}, 7000);
				return () => clearInterval(timer); // Return cleanup function
			}

			isMounted.current = true;

			if (affrefid) {
				localStorage.setItem('affrefid', affrefid);
			} else if (localStorage.getItem('affrefid')) {
				const affrefid2 = localStorage.getItem('affrefid');
			}

			const cleanupTimer = setTimer1(); // setTimer1 now returns a cleanup function

			return () => {
				isMounted.current = false;
				cleanupTimer(); // Clear the interval
			};
		},
		[ affrefid, imagesArray ]
	);

	React.useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const handleImageChange = (newIndex) => {
		setImgsrc(imagesArray[newIndex]);
		setIndex(newIndex);
	};

	const handleNext = () => {
		let nextIndex = (index + 1) % imagesArray.length; // Wrap around if exceeds the length
		handleImageChange(nextIndex);
	};

	function determineIndexClass(i) {
		if (i === index) return styles.thumbnailActive;
		return styles.thumbnail;
	}

	const handleNextBut = () => {
		if (thumbStart + 32 <= thumbsArray.length) {
			// Ensure that there's room to shift forward
			setThumbStart(thumbStart + 16);
		} else {
			setThumbStart(Math.max(thumbsArray.length - 16, 0)); // If not enough room for a full shift, just show the last set of thumbnails
		}
	};

	const handlePrevBut = () => {
		if (thumbStart - 16 >= 0) {
			setThumbStart(thumbStart - 16);
		} else {
			setThumbStart(0);
		}
	};

	const handleNextBut2 = () => {
		if (thumbStart + 33 <= thumbsArray.length) {
			// Ensure that there's room to shift forward
			setThumbStart(9);
		} else {
			setThumbStart(Math.max(0)); // If not enough room for a full shift, just show the last set of thumbnails
		}
	};

	const handlePrevBut2 = () => {
		if (thumbStart - 16 >= 0) {
			setThumbStart(thumbStart - 16);
		} else {
			setThumbStart(0);
		}
	};

	function saveImage(imgUrl) {
		const a = document.createElement('a');
		a.href = imgUrl;
		a.target = '_blank';
		a.rel = 'noreferrer';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	const text =
		'AI image generator for your cat & dog - create funny & cute costume images, pet portraits - all in 4K resolution and quality that you can print - without the dressup.';
	const title = 'AI Pet Photos - AI image generator for pets';

	return (
		<div className={styles.wrap}>
			<HelmetProvider>
				<Helmet>
					<meta charSet="utf-8" />

					<title>AI Pet Photos - AI Image Generator for Cats & Dogs | Pet Portraits & Furry Art</title>
					<meta property="og:title" content={title} />
					<meta name="twitter:title" content={title} />

					<meta
						name="description"
						content="Create stunning AI-generated pet portraits and furry art for your cats and dogs. Our AI image generator transforms your pet photos into high-quality 4K images with various characters and styles."
					/>
					<meta property="og:description" content={text} />
					<meta name="twitter:description" content={text} />

					<link rel="canonical" href="https://aipetphotos.com/" />
					<meta name="twitter:creator" content="@aipetphotos" />
					<meta name="twitter:card" content="summary" />
					<meta name="twitter:site" content="@aipetphotos" />

					<meta property="og:url" content="https://aipetphotos.com" />
					<meta property="og:image" content="https://aipetphotos.com/social-og2-small-sq.jpg" />
					<meta property="twitter:image" content="https://aipetphotos.com/social-og2-small-sq.jpg" />
					<meta name="twitter:url" content="https://aipetphotos.com" />

					<meta
						name="keywords"
						content="AI pet photos, pet portraits, furry art, cat portraits, dog portraits, AI image generator, 4K pet images, custom pet art"
					/>

					<script type="application/ld+json">
						{JSON.stringify({
							'@context': 'http://schema.org',
							'@type': 'Organization',
							url: 'https://aipetphotos.com',
							logo: 'https://aipetphotos.com/logo-amp.webp',
							image: 'https://aipetphotos.com/social-og2.jpg',
							sameAs: [
								'https://www.twitter.com/aipetphotos',
								'https://www.tiktok.com/@aipetphotos.com',
								'https://www.instagram.com/aipetphotos.com',
								'https://www.facebook.com/100093992422539/'
							]
						})}
					</script>

					<script type="application/ld+json">
						{JSON.stringify({
							'@context': 'http://schema.org',
							'@type': 'Product',
							brand: 'AI Pet Photos Generator',
							name: 'AI image generator Starter Bundle',
							image:
								'https://res.cloudinary.com/df8ne8pla/image/upload/v1704536654/blog/product6_xckf58.webp',
							description: '6 AI generated images of your pet.',
							offers: {
								'@type': 'Offer',
								priceCurrency: 'USD',
								price: '3.99',
								itemCondition: 'http://schema.org/NewCondition',
								availability: 'http://schema.org/InStock',
								priceValidUntil: '2024-10-01'
							}
						})}
					</script>

					<script type="application/ld+json">
						{JSON.stringify({
							'@context': 'http://schema.org',
							'@type': 'Product',
							brand: 'AI Pet Photos Generator',
							name: 'AI image generator Saver Bundle',
							image:
								'https://res.cloudinary.com/df8ne8pla/image/upload/v1704536655/blog/product12_eetah4.webp',
							description: '12 AI generated images of your pet.',
							offers: {
								'@type': 'Offer',
								priceCurrency: 'USD',
								price: '6.99',
								itemCondition: 'http://schema.org/NewCondition',
								availability: 'http://schema.org/InStock',
								priceValidUntil: '2024-10-01'
							}
						})}
					</script>

					<script type="application/ld+json">
						{JSON.stringify({
							'@context': 'http://schema.org',
							'@type': 'Product',
							brand: 'AiPetPhotos.com',
							name: 'AI image generator Top Price Bundle',
							image:
								'https://res.cloudinary.com/df8ne8pla/image/upload/v1704536656/blog/product21_jkjbza.webp',
							description: '21 AI generated photos of your pet.',
							offers: {
								'@type': 'Offer',
								priceCurrency: 'USD',
								price: '10.99',
								itemCondition: 'http://schema.org/NewCondition',
								availability: 'http://schema.org/InStock',
								priceValidUntil: '2024-10-01'
							}
						})}
					</script>
					<script type="application/ld+json">
						{JSON.stringify({
							'@context': 'http://schema.org',
							'@type': 'Product',
							name: 'AI Pet Photos Generator',
							aggregateRating: {
								'@type': 'AggregateRating',
								ratingValue: '4.7',
								reviewCount: '7'
							}
						})}
					</script>
				</Helmet>
			</HelmetProvider>
			{/* 
      <div className={styles.title}>

      </div> */}
			<h1 className={styles.inblue}>
				<i>
					<strong>AI Pet Photos - Transform Your Cats & Dogs into Stunning Furry Art</strong>
				</i>
			</h1>
			<h2 className={styles.h2}>
				#1 AI Image Generator for High-Quality Pet Portraits
				<br className={styles.breakH2} />  Cats & Dogs in 4K Resolution
			</h2>
			<div className={styles.wraph2}>
				<h3 className={styles.inblue2}>
					<div className={styles.inblue2Strong}>
						<span>Your Dog & Cat</span>
						<Samples index={index} />
						<span>AI Generated</span>
					</div>
				</h3>

				<div className={styles.googleRev}>
					<p>
						<b>Top Rated Service</b> <br />
						<a className={styles.fullfill} href="https://g.co/kgs/P8ZjA5V">
							4.8 &#9733;&#9733;&#9733;&#9733;
						</a>
						<a className={styles.halfFil}>&#9733;</a>
					</p>

					<a href="https://g.co/kgs/P8ZjA5V">
						<img src={googleLogo} className={styles.glogo} alt="google logo" />
					</a>
					<small style={{ margin: '0px', color: 'gray' }}>as of 04/24</small>
				</div>
			</div>
			<div className={styles.section1}>
				<div className={styles.leftSec}>
					{/* main display */}
					<div className={styles.carousel}>
						{imgsrc && (
							<img
								src={imgsrc}
								onClick={handleNext}
								title="AI generated image of a pet"
								alt="AI generator images of a pet as different fursona characters"
								className={styles.coverImage}
							/>
						)}
					</div>

					{/* <h1 className={styles.wraph1Mobile}>
            <i><strong>Pet Portraits & Furry Art</strong></i>
          </h1> */}
					<h4 className={styles.h4mob}>21 Characters in 4K resolution</h4>

					<div className={styles.thumbDivMob}>
						<button className={styles.thumbBut} onClick={handlePrevBut}>
							{'<'}
						</button>

						{thumbsArray.map((img, actualIndex) => {
							let shouldRender = false;
							if (
								thumbStart <= actualIndex &&
								actualIndex < Math.min(thumbStart + 16, thumbsArray.length)
							) {
								shouldRender = true;
							}

							if (shouldRender) {
								return (
									<div className={styles.thumbdivone} key={actualIndex}>
										<img
											key={img + actualIndex}
											className={determineIndexClass(actualIndex)}
											src={img}
											alt={`AI generated Thumbnails ${actualIndex}`}
											onClick={() => handleImageChange(actualIndex)}
										/>
										<small>{actualIndex}</small>
									</div>
								);
							} else {
								return null;
							}
						})}
						<button className={styles.thumbBut} onClick={handleNextBut}>
							{'>'}
						</button>
					</div>

					<br />
					<div className={styles.mobUiCover}>
						<Link to="/product/cat-portrait-ai-4k" className={styles.galleryLinkMob}>
							See Cats Only
						</Link>
						<Link to="/product/dog-portrait-ai-4k" className={styles.galleryLinkMob}>
							See Dogs Only
						</Link>
						{/* <Link to="/pet-portraits-gallery/1" className={styles.galleryLinkMob}>Portraits Gallery</Link> */}

						<br />

						<button onClick={() => saveImage(dogUrl)} className={styles.sampleBut2}>
							<small>4K Sample Dog</small>
						</button>

						<button onClick={() => saveImage(catUrl)} className={styles.sampleBut2}>
							<small>4K Sample Cat</small>
						</button>
					</div>
				</div>

				<div className={styles.rightFirstSec}>
					<div className={styles.howitworks1}>
						<h3 className={styles.benefitsHeading}>How It Works</h3>

						<p className={styles.describe}>
							Simply select just 15 photos of your cat or dog, and receive up to 21 AI generated images
							showcasing your pet as different characters!
						</p>

						<div className={styles.howtoBox}>
							<div className={styles.howtoStep}>
								<img src={cloudIcon} alt="upload icon" className={styles.icon2} />
								<p>1. Select Pet Photos</p>
							</div>

							<div className={styles.howtoStep}>
								<img src={aiIcon} alt="artificial intelligence icon" className={styles.icon2} />
								<p>2. AI Learns & Creates Images</p>
							</div>

							<div className={styles.howtoStep}>
								<img src={picIcon} alt="artwork icon" className={styles.icon2} />
								<p> 3. Enjoy your AI photos!</p>
							</div>
						</div>
					</div>
					<div className={styles.servicePackage}>
						{/* end */}

						{/* thumbnails */}

						<h4 className={styles.hideMob1}>Explore 21 AI image templates for Your Dog or Your Cat</h4>
						<div className={styles.thumbDivHide}>
							<button className={styles.thumbBut} onClick={handlePrevBut2}>
								{'< Back'}
							</button>

							{thumbsArray.map((img, actualIndex) => {
								let shouldRender = false;
								if (
									thumbStart <= actualIndex &&
									actualIndex < Math.min(thumbStart + 34, thumbsArray.length)
								)
									shouldRender = true;
								if (shouldRender) {
									return (
										<div className={styles.thumbdivone} key={actualIndex}>
											<img
												key={img + actualIndex}
												className={determineIndexClass(actualIndex)}
												src={img}
												alt={`AI generated pet, thumbnail number ${actualIndex}`}
												onClick={() => handleImageChange(actualIndex)}
											/>
											<small>{actualIndex + 1}</small>
										</div>
									);
								} else {
									return null;
								}
							})}

							<button className={styles.thumbBut} onClick={handleNextBut2}>
								{'More >'}
							</button>
							<small className={styles.hideSmallMob}>34 of 42 Portrait Images</small>

							<small className={styles.smallBelowPrice}>
								<Link to="/product/cat-portrait-ai-4k" className={styles.galleryLink}>
									See Cats Only
								</Link>
								<Link to="/product/dog-portrait-ai-4k" className={styles.galleryLink}>
									See Dogs Only
								</Link>
								{/* <Link to="/pet-portraits-gallery/1" className={styles.galleryLink}>Browse Portraits Gallery</Link> */}
								<button onClick={() => saveImage(dogUrl)} className={styles.sampleBut2}>
									<small>Full-size 4K Dog Sample</small>
								</button>
								<button onClick={() => saveImage(catUrl)} className={styles.sampleBut2}>
									<small>Full-size 4K Cat Sample</small>
								</button>
								<br />
								For a full list of our AI image templates, please proceed to checkout or email support
								at the end of the page. All prices quoted include tax or VAT.
							</small>
							<a
								href="https://www.youtube.com/watch?v=agAEV_4YtHo"
								target="_blank"
								rel="noreferrer"
								className={styles.para}
							>
								{/* 11% off with code <strong>CYBERPETS11</strong> + Free Bonus New Feature */}
								Watch our New Video Guide
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.googleRevMob} style={{ justifyContent: 'center', margin: '10px' }}>
				<p>
					<b>Top Rated Service</b> <br />
					<a className={styles.fullfill} href="https://g.co/kgs/P8ZjA5V">
						{' '}
						4.8 &#9733;&#9733;&#9733;&#9733;
					</a>
					<a className={styles.halfFil} href="https://g.co/kgs/P8ZjA5V">
						&#9733;
					</a>{' '}
				</p>
				<a href="https://g.co/kgs/P8ZjA5V">
					<img src={googleLogo} className={styles.glogo} alt="google logo" />
				</a>
				<small style={{ margin: '0px', color: 'gray' }}>as of 4/24</small>
			</div>
			<Bundles visible={true} />
			<div className={styles.seenWrap}>
				<p style={{ textAlign: 'center' }}>
					<b>As Seen On</b>
				</p>
				<div className={styles.seenImages}>
					<img src={seen1} className={styles.seenOn} alt="fashion logo" />
					<img src={seen2} className={styles.seenOn} alt="logo bridesmaids" />
					<img src={seen3} className={styles.seenOn} alt="logo buzznews" />
				</div>
			</div>
			<div className={styles.featureWrap}>
				<h3>Features of Our AI Pet Portrait Generator</h3>

				<div className={styles.featureUL}>
					<div className={styles.featureULItem}>AI-generated custom pet portraits</div>
					<div className={styles.featureULItem}>High-detail furry art creation</div>
					<div className={styles.featureULItem}>4K resolution (4096 x 4096 pixels)</div>
					<div className={styles.featureULItem}>Fast delivery in just 17 minutes*</div>
					<div className={styles.featureULItem}>Professional lighting and image quality</div>
					<div className={styles.featureULItem}>Excellent pet resemblance</div>
					<div className={styles.featureULItem}>Minimal deformation occurrences</div>
					<div className={styles.featureULItem}>Various character styles for cats and dogs</div>
				</div>

				<small>* Average time to process, actual time may vary depending on demand and servers load.</small>
			</div>
			<div className={styles.howitworks1Mob}>
				<h3 className={styles.benefitsHeading}>How It Works</h3>

				<p className={styles.describe}>
					Simply select just 15 photos of your cat or dog, and receive up to 21 AI generated images showcasing
					different characters!
				</p>

				<div className={styles.howtoBox}>
					<div className={styles.howtoStep}>
						<img src={cloudIcon} alt="upload icon" className={styles.icon2} />
						<p>1. Select Pet Photos</p>
					</div>
					<div className={styles.howtoStep}>
						<img src={aiIcon} alt="artificial intelligence icon" className={styles.icon2} />
						<p>2. AI Learns & Creates Images.</p>
					</div>
					<div className={styles.howtoStep}>
						<img src={picIcon} alt="artwork icon" className={styles.icon2} />
						<p> 3. Enjoy Your AI images!</p>
					</div>
				</div>

				{/* <Link style={{ alignSelf: 'center', margin: '15px', textDecoration: 'none', }} to="pet-portrait-images-use-cases">See More Use Cases</Link> */}
			</div>
			<div>
				<div className={styles.benefitsContainer}>
					<div className={styles.testimonials}>
						<h4 className={styles.testimonialHeading}>
							<strong>What Our Customers Say About AI Pet Photos:</strong>
						</h4>
						<Suspense fallback={<div>Loading Testimonials...</div>}>
							<Testimonials testimonials={testimonialsData} />
						</Suspense>
					</div>

					<h4 className={styles.benefitsHeading}>Why Choose Our AI Pet Portrait Service</h4>

					<div className={styles.benefitsWrapper}>
						<div className={styles.benefitItem}>
							<h5 className={styles.benefitHeading3}>Unique AI-Generated Custom Pet Portraits</h5>
							<p>
								Our AI creates entertaining and high-quality images of your pets as various characters,
								perfect for cat and dog lovers.
							</p>
						</div>

						<div className={styles.benefitItem}>
							<h5 className={styles.benefitHeading3}>4K Resolution Furry Art</h5>
							<p>
								We provide ultra-high-resolution 4K images suitable for printing or sharing online,
								ensuring the best quality for your pet portraits.
							</p>
						</div>

						<div className={styles.benefitItem}>
							<h5 className={styles.benefitHeading3}>Easy-to-Use Pet Portrait Generator</h5>
							<p>
								Transform your cat or dog into a superhero, samurai, cowboy, or viking with just a few
								clicks. No design skills or prompt engineering needed.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Suspense fallback={<div>Loading Frequently Asked Questions...</div>}>
				<Faqs />
			</Suspense>
			{/* <div className={styles.bottomLinks}> */}
			{/* <Link to="/pet-portrait-images-use-cases" className={styles.galleryLinkMob} galleryLinkMob
          style={{ maxWidth: '50%', alignSelf: 'center', margin: '10px' }}>See More Use Cases</Link> */}
			{/* </div> */}
			<Link
				to="https://aimylogo.com"
				className={styles.galleryLink}
				style={{ maxWidth: '50%', alignSelf: 'center', margin: '10px', display: 'block' }}
			>
				Visit our new AI Logo Generation site - AImyLogo.com
			</Link>
		</div>
	);
}
